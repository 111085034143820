html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: "Arbutus Slab";
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  margin: 0;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  box-sizing: border-box;
}

body {
  background-image: url('./assets/TILE_FINAL.png');
  background-size: 300px;
  background-color: #ecfeff;
  background-size: repeat;
}

.nav {
  margin-left: auto;
  margin-right: auto;

  margin-top: 25px;
}

.dl-copy-regen {
  background: none;
  border: 1px solid black;
  height: 20px;
}

.dl-copy-regen:disabled {
  border: none;
}

.hero-link {
  color: #df7cb5;
  font-style: none;
  text-decoration: none;
  height: 100%;
  display: inline-block;
}

h1 {
  font-size: 40px;
  text-align: center;
  margin: 0;
  height: 60px;
}

h2 {
  font-size: 22pt;
  font-weight: bold;
}

.loading {
  font-size: 36px;
  text-align: center;
  margin-top: 75px;
}

.error-message {
  color: red;
  margin-top: 3px;
  margin-bottom: 3px;
}

.error {
  text-align: center;
  margin-top: 50px;
}

.instructions {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: left;
  max-width: 400px;
  background-color: aquamarine;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 5px 5px lightsalmon;
  line-height: 27px;
  animation-name: instructions;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.continue-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.continue {
  font-size: 18px;
  border: 1px solid black;
  background: none;
}

.input {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.url-settings {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none !important;
}

.url {
  width: 65vw;
  height: 30px;
  border: 1px black solid;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 5px;
  margin-top: 5px;
  background: none;
}

.go {
  border: 1px solid black;
  border-radius: 4px;
  height: 40px;
  margin-left: 5px;
  text-decoration: none;
  color: black;
  cursor: default;
  font-size: 20px;
  padding: 5px;
}

.setting-label {
  background-color: #ebfeff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  padding-bottom: 2px;
}

.toggle-settings {
  border: 1px solid lightgray;
  border-radius: 2px;
}

fieldset {
  border: none;
}

.poem-type, .syllables, .lines {
  border: 1px solid lightgray;
  margin: 5px;
}

.syllables, .lines {
  width: 20px;
}

.settings {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.setting-container {
  margin: 1px;
}

.sample-poems-label {
  /*position: fixed;
  bottom: 50px;*/
  font-style: italic;
  margin-bottom: 15px;
}

.back-regen-buttons {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
}

.results {
  max-width: 500px;
  border: 1px solid black;
  box-shadow: 3px 3px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
}

.bg-blue {
  background-color: #aee4f3;
}

.bg-green {
  background-color: #ddf5c8;
}

.bg-pink {
  background-color: #f9c4e4;
}

.bg-blue-card {
  background-color: #91ceef;
}

.bg-green-card {
  background-color: #a9c9ba;
}

.bg-pink-card {
  background-color: #f3abb4;
}

.poem-title {
  margin-bottom: 10px;
  margin-top: 10px;
}

.poem-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
}

.poem-line {
  margin-bottom: 5px;
  margin-top: 5px;
}

.poem-line-empty {
  margin-bottom: 15px;
  margin-top: 15px;

}

.poem-results {
  margin-bottom: 75px;
  z-index: 1;
  position: relative;
}

.share-buttons {
  display: flex;
  width: 278px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

.copy-status-container {
  width: 100%;
  margin-bottom: 25px;
}

.copy-status {
  font-style: bold;
  margin-left: 55%;
  padding: 4px;
  border-radius: 4px;
  background: black;
  color: white;
}

.intro-md {
  width: 387px;
  height: 450px;
}

footer {
  position: fixed;
  bottom: 15px;
  left: 15px;
  margin-top: 30px;
}

@media screen and (max-width: 420px) {
  .instructions {
    margin-left: 10px;
    margin-right: 10px;
  }
  .intro-md {
    width: 87vw;
    height: 30vh;
  }
}

@media screen and (max-width: 540px) {
  html, body {
    overflow: auto;
    height: 100%;
  }
}

@media screen and (min-width: 540px) {
  .results {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .poem-line {
    margin-bottom: 18px;
  }

  .error-message {
    text-align: right;
    float: right;
  }
}

@media screen and (min-width: 700px) {
  .url {
    width: 475px;
  }
  .input {
    margin-top: 150px;
  }
}

.animated {
  text-shadow: black 5px 5px;
  animation-name: title;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes title {
  0% {text-shadow: black 5px 5px;}
  25% {text-shadow: black -5px 5px;}
  50% {text-shadow: black -5px -5px;}
  75% {text-shadow: black 5px -5px;}
}

@keyframes instructions {
  0% {box-shadow: rgb(255, 134, 245) 5px 5px;}
  25% {box-shadow: rgb(246, 202, 255) -5px 5px;}
  50% {box-shadow: rgb(255, 180, 150) -5px -5px;}
  75% {box-shadow: pink 5px -5px;}
}